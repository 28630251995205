<template>
	<div
		id="app"
		class="sm:flex flex-grow justify-center w-full min-w-screen h-full bg-white sm:bg-gray-100 font-inter min-h-screen"
		:class="{ 'items-center': $route.name !== 'projectVersionHistory' }"
	>
		<router-view />
	</div>
</template>

<script>
// Глобальные данные стора
import { mutations } from './store/store';

export default {
	name: 'App',
	watch: {
		$route: {
			immediate: true,
			handler(to, from) {
				if (from && to.path === '/') mutations.resetGlobalUser();
				document.title = to.meta.title || 'НОК';
			}
		}
	}
};
</script>
