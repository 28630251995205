import Axios from 'axios';
import Vue from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { mutations } from './store/store';
import './assets/tailwind.css';
import './assets/custom.css';
import vSelect from 'vue-select';

Vue.component('v-select', vSelect);
Vue.use(Toast, {
	transition: 'Vue-Toastification__bounce',
	maxToasts: 10,
	newestOnTop: true
});

Vue.use(require('vue-moment'));

Vue.config.productionTip = false;
Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.baseURL = 'https://apiosnok.minzdrav.gov.ru/';

Vue.prototype.$DefaultPageSize = 100;
Vue.prototype.$MaxPageSize = 999999;

const token = localStorage.getItem('token') || '';

if (token) {
	Vue.prototype.$http.defaults.headers.common.Authorization = `Bearer ${token}`;
}

// Фильтр
function inputHandler(e) {
	let ch = String.fromCharCode(e.which);
	let re = new RegExp(this.value);
	if (!ch.match(re)) {
		e.preventDefault();
	}
}

Vue.directive('filter', {
	bind: function (el, binding) {
		el.addEventListener('keypress', inputHandler.bind(binding));
	},
	unbind: function (el) {
		el.removeEventListener('keypress', inputHandler);
	}
});

// Обработка ошибок Axios
Vue.prototype.$http.interceptors.response.use(
	(res) => {
		return res;
	},
	(error) => {
		if (error.response.status === 401) {
			mutations.resetGlobalUser();
			router.replace('/');
		} else if (error.response.status === 500) {
			console.log('status 500 auth');
		}
		return Promise.reject(error);
	}
);

new Vue({
	router,
	render: (h) => h(App)
}).$mount('#app');
